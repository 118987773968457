import * as React from "react"
import CaseStudyTemplate from "../components/case-study-template";
import { StaticImage } from "gatsby-plugin-image";

const content = {
	title: `Asahi Beverages`,
	heading: 'Asahi Staff Rewards Quiz.',
	emphasisedText: 'There’s power in internal brand advocacy',
	text: [
		'Investing in your employees will always pay back in the long run. That’s why it is so important to transform them into powerful brand advocates. How well do your staff know your brand?', 
		'We worked with Wunderman Thompson to help Asahi engage, educate and reward their employees with our latest promotion, by creating a weekly quiz only accessible via an SMS bit link. Each week they would learn about Asahi and be instantly quizzed on their new knowledge in exchange for an entry into the prize draw.',
		'Rewarding staff is crucial because your employees can be your best ambassadors.'
	],
	numbers: {
		'Staff members': '3000',
		Questions: '101',
		'In prizes': '$32K',
	},
};

export default function Asahi() {
	return (
		<CaseStudyTemplate 
			heading={content.heading}
			superHeading={content.title}
			image={<StaticImage alt="Hero image" src="../images/Asahi@3x.png" width={500} height={500} />}
			content={{
				title: content.emphasisedText,
				text: content.text,
			}}
			numbers={content.numbers}
		/>
	)
}
